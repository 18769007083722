import React, { useState } from "react"
import { Link } from "gatsby"
import { Button, Form } from "react-bootstrap"
import Select from "react-select"
import linkarrow from "../../../src/images/contactus/link-arrow.svg"
import re from "../../../src/images/contactus/re.svg"
import head from "../../../src/images/contactus/head.svg"
import orange from "../../../src/images/contactus/orange.svg"
import vill from "../../../src/images/contactus/viil.svg"
import vm from "../../../src/images/contactus/vm.svg"
import "./contactus.css"
import { apiClient } from "../../apiClient"

const options = [
  { value: "team1", label: "team1" },
  { value: "team2", label: "team2" },
  { value: "team3", label: "team3" },
]
const optionstopic = [
  { value: "example1", label: "example1" },
  { value: "example2", label: "example2" },
  { value: "example3", label: "example3" },
]

const customStyles = {
  option: provided => ({
    ...provided,
    background: "#3d41600a",
    color: "#212529",
    fontWeight: "400",
    fontSize: "16px",
    padding: "12px",
    lineHeight: "16px",
    cursor: "pointer",
    ":hover": {
      background: "#fff",
      // border: "2px solid #306CFE",
    },
  }),
  menu: provided => ({
    ...provided,
    background: "#fff",
    padding: "0px",
    border: "2px solid transparent",
  }),
  control: () => ({
    background: "#3d41600a",
    border: "2px solid transparent",
    borderRadius: "10px",
    color: "#fff",
    display: "flex",
    alignItem: "center",
    height: "50px",
    margin: "10px 0",
    ":hover": {
      background: "#fff",
      border: "2px solid rgba(61, 65, 96, 0.04)",
      // border: "2px solid #306CFE",
    },
  }),
  singleValue: provided => ({
    ...provided,
    color: "#000",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "16px",
  }),
  indicatorSeparator: provided => ({
    ...provided,
    display: "none",
  }),
  placeholder: provided => ({
    ...provided,
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "19px",
    color: "#3d416066",
  }),
  input: provided => ({
    ...provided,
    height: "40px",
  }),
}

const errorStyle = {
  option: provided => ({
    ...provided,
    background: "#3d41600a",
    color: "#212529",
    fontWeight: "400",
    fontSize: "16px",
    padding: "12px",
    lineHeight: "16px",
    cursor: "pointer",
    ":hover": {
      background: "#fff",
    },
  }),
  menu: provided => ({
    ...provided,
    background: "#fff",
    padding: "0px",
    border: "2px solid red",
  }),
  control: () => ({
    background: "#3d41600a",
    border: "2px solid red",
    borderRadius: "10px",
    color: "#fff",
    display: "flex",
    alignItem: "center",
    height: "50px",
    margin: "10px 0",
    ":hover": {
      background: "#fff",
      border: "2px solid red",
    },
  }),
  indicatorSeparator: provided => ({
    ...provided,
    display: "none",
  }),
}
const successStyle = {
  option: provided => ({
    ...provided,
    background: "#3d41600a",
    color: "#212529",
    fontWeight: "400",
    fontSize: "16px",
    padding: "12px",
    lineHeight: "16px",
    cursor: "pointer",
    ":hover": {
      background: "#fff",
    },
  }),
  menu: provided => ({
    ...provided,
    background: "#fff",
    padding: "0px",
    border: "2px solid transparent",
  }),
  control: () => ({
    background: "#3d41600a",
    border: "2px solid #36A836",
    borderRadius: "10px",
    color: "#fff",
    display: "flex",
    alignItem: "center",
    height: "50px",
    margin: "10px 0",
    ":hover": {
      background: "#fff",
      border: "2px solid #36A836",
    },
  }),
  indicatorSeparator: provided => ({
    ...provided,
    display: "none",
  }),
}
const ContactUs = ({ data }) => {
  console.log({ data });
  const [error, setError] = useState(true)
  const [success, setSuccess] = useState(true)
  const [formData, SetFormData] = useState({})
  const submitForm = () => {



    let res = apiClient.post('/api/contact-messages', {
      data: { ...formData, submission_date: new Date() }
    }).then(() => {
      SetFormData({})
      alert(data?.form_success_message || "You message sent successfully!.")
      window.location.reload()
    }).catch(() => {
      alert(data?.form_error_message || "Oops! Something went wrong while submitting your message.")
    })

  }

  return (
    <div className="contact-us-content">
      <div className="container">
        <div className="contact-us-item">
          <div className="contact-us-detail">
            <h3>
              {data?.title}
              {/* When you’re ready to take your business to the next level, we’re
              here to help. */}
            </h3>
            <p>
              {data?.description}
              {/* To get started, simply give us a call or send us an email today.
              We’ll be happy to discuss your needs and set up a time to help you
              get started with your account specialist! */}
            </p>
            <div className="contact-us-support">
              <h4>{data?.support_title}</h4>
              <p>
                {data?.support_description}
                {/* Our team is here to help. Please don’t hesitate to reach out.
                We’re available to answer any of your questions. */}
              </p>

              <Link to={data?.contact_support?.link}>
                {data?.contact_support?.text}
                <img src={linkarrow} alt="arrow" />
              </Link>
            </div>
            <div className="contact-us-info">
              <h4>Contact Info</h4>
              <div className="contact-us-phone">
                <span>Phone:</span>
                <a href={`tel:${data?.phone}`} target="_blank" rel="noreferrer">
                  {data?.phone}
                </a>
              </div>
              <div className="contact-us-phone">
                <span>Support:</span>
                <a
                  href={`mailto:${data?.support_mail}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {data?.support_mail}

                </a>
              </div>
              <div className="contact-us-phone">
                <span>Sales:</span>
                <a
                  href={`mailto:${data?.sales_mail}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  {data?.sales_mail}
                </a>
              </div>
              <div className="contact-us-phone">
                <span>General:</span>

                <a
                  href={`mailto:${data?.general_mail}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  {data?.general_mail}
                </a>
              </div>
            </div>
            <div className="contact-us-mail">
              <h4>Snail Mail</h4>
              <p>1891 NE 164th St North Miami Beach, FL 33162</p>
            </div>
            <div className="contact-us-goodhand">
              <h4>You’re in good hands</h4>
              <p>
                Plenty of companies from all around the workd have trusted
                Marketeq as their source of growth and strategy
              </p>
              <div className="goodhand-img">
                <img src={re} alt="re" />
                <img src={head} alt="head" />
                <img src={orange} alt="orange" />
                <img src={vill} alt="viil" />
                <img src={vm} alt="vm" />
              </div>
            </div>
          </div>
          <div className="contact-us-form">
            <h3>{data?.form_title}</h3>
            <p>
              {/* Call us at (786) 529-7761, or use the form below to get in touch
              with us. */}
              {data?.form_description}
            </p>
            <Form>
              {
                data?.form_fields?.map((field) => {
                  if (field?.input_type === 'select') {
                    return (
                      <>

                        <label >{field?.label}</label>
                        <div className="contact-input  ">
                          <Select
                            onChange={(e) => {
                              console.log(e)
                              SetFormData({ ...formData, [field?.name]: e.value })
                            }}
                            // value={formData[field?.name]}
                            name={field?.name}
                            options={field?.options?.map(opt => ({ value: opt?.value, label: opt?.label }))}
                            styles={customStyles}
                            // styles={success ? successStyle : customStyles}
                            placeholder={<span>{field?.placeholder} </span>}
                          />
                          {/* <h6>Error message goes here</h6> */}
                        </div>
                      </>
                    )
                  }
                  else if (field?.input_type === 'textarea') {
                    return (
                      <>
                        <label >{field?.label}</label>
                        <div className="contact-input ">
                          <textarea
                            required={field?.required}
                            value={formData[field?.name]}
                            onChange={(e) => {
                              SetFormData({ ...formData, [field?.name]: e.target.value })
                            }}

                            name={field?.name}
                            type="text"
                            className="form-control"
                            style={{ minHeight: "12rem" }}
                            placeholder={field?.placeholder}
                          />
                          {/* <h6>Error message goes here</h6> */}
                        </div>
                      </>
                    )
                  }
                  else {
                    return (
                      <>
                        <label >{field?.label}</label>

                        <div className="contact-input">
                          <input
                            required={field?.required}
                            onChange={(e) => {
                              console.log(e.target.value);
                              SetFormData({ ...formData, [field?.name]: e.target.value })
                            }}
                            value={formData[field?.name]}
                            name={field?.name}
                            type={field?.input_type}
                            placeholder={field?.placeholder}
                            className="form-control "
                          />
                          {/* <h6>Error message goes here</h6> */}
                        </div>
                      </>

                    )

                  }
                })
              }
              <Button onClick={submitForm}>send message</Button>
            </Form>
          </div>
        </div>
      </div>
    </div>
  )
}
export default ContactUs
