import React from "react"
import { Helmet } from "react-helmet"
import ContactUs from "../components/Contactus/Contactus"
import Layout from "../components/Layout/layout"

const ContactUsPage = (
  { pageContext }
) => {
  const { navBar, footer, contactPage } = pageContext
  return (
    <Layout
      footer={footer}
      navBar={navBar}
    >
      <Helmet>
        <body className="contact-page" />
      </Helmet>
      <ContactUs data={contactPage} />
    </Layout>
  )
}
export default ContactUsPage
